import { useDispatch, useSelector } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Modal, ModalHeader, ModalBody, Button,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { createChangeLeverageRequest, getAccountTypesStart } from "store/actions";
import Loader from "components/Common/Loader";
import { useEffect, useState } from "react";
import config from "config";

const LeverageModal = ({ isOpen, toggle, accounts, t }) => {
  console.log("accounts: ", accounts);
  const dispatch = useDispatch();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [leverageOptions, setLeverageOptions] = useState(config.LEVERAGES);
  const [defaultLeverage, setDefaultLeverage] = useState("");
  const createChangeLeverageRequestLoading = useSelector(state => state?.forex?.requests?.createChangeLeverageRequestLoading);
  const handleValidSubmit = (event, values) => {
    dispatch(createChangeLeverageRequest({
      _id: values.account,
      to: values.leverage,
      from: values.from,
      login: values.login,
      platform: values.platform
    }));
  };
  const { accountTypes, submitting } = useSelector((state) => state.forex.accounts);

  useEffect(() => {
    if (accounts.length > 0) {
      const defaultAccount = accounts[0];
      setSelectedAccount(defaultAccount._id);
      setDefaultLeverage(defaultAccount?.MarginLeverage);
    }
  }, [accounts]);

  useEffect(() => {
    dispatch(getAccountTypesStart({
      forCp: true,
    }));
    return () => {
      dispatch(getAccountTypesStart());
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedAccount && accountTypes.length > 0) {
      const account = accounts.find((acc) => acc._id === selectedAccount);
      if (account) {
        const accountType = accountTypes.find((type) => type._id === account?.accountTypeId?._id);
        setLeverageOptions(accountType?.leverages || config.LEVERAGES);
        setDefaultLeverage(account.MarginLeverage);
      }
    }
  }, [selectedAccount, accountTypes, accounts]);

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {t("Change Leverage")}
      </ModalHeader>
      <ModalBody>
        <AvForm
          onValidSubmit={(e, v) => {
            v.from = accounts.filter((account) => (account._id == v.account))[0]?.MarginLeverage;
            v.login = accounts.filter((account) => (account._id == v.account))[0]?.login;
            v.platform = accounts.filter((account) => (account._id == v.account))[0]?.platform;
            handleValidSubmit(e, v);
          }}
        >
          <AvField 
            type="select" 
            name="account" 
            value={selectedAccount || ""} 
            label={t("Select Account")} 
            className="form-select mt-1 mb-2" 
            required
            onChange={(e) => {
              const newAccountId = e.target.value;
              setSelectedAccount(newAccountId);
              const account = accounts.find((acc) => acc._id === newAccountId);
              if (account) {
                setDefaultLeverage(account.MarginLeverage);
              }
            }}
          >
            {accounts.map((account) => (
              <option key={account?.login} value={account?._id}>
                {account?.login}
              </option>
            ))}
          </AvField>
          
          <AvField 
            type="select" 
            name="leverage" 
            value={defaultLeverage || ""} 
            label={t("Select Leverage")} 
            className="form-select mt-1" 
            required
          >
            {leverageOptions.map((leverage) => (
              <option key={leverage} value={leverage}>1:{leverage}</option>
            ))}
          </AvField>
          <div className="text-center mt-3 mb-1">
            {
              createChangeLeverageRequestLoading
                ? 
                <Loader />
                : 
                <Button type="submit" className="border-0 color-bg-btn shadow">{t("Change")}</Button>
            }
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(LeverageModal);