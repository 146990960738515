import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import {
  Button, Col, FormGroup, Input, Label, Row
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { 
  switchSubPortal, 
  toggleCurrentModal, 
  convertProfile,
  logoutUser,
} from "store/actions";
import { CUSTOMER_SUB_PORTALS } from "common/constants";
import SubmitIndvidualProfile from "components/Journey/Profiles/IndvidualProfile";
import { HIDE_JOU_IND_PROFILE } from "common/data/jourenykeys";
import { useEffect, useState } from "react";
import { JClickHandler } from "components/Journey/handlers";
import { setOptions } from "leaflet";
import { Link } from "react-router-dom/cjs/react-router-dom";

function PageHeader(props) {
  const shouldDisplayWithoutTitle = props.shouldDisplayWithoutTitle ? props.shouldDisplayWithoutTitle : false;
  const { t } = useTranslation();
  const { portal } = useSelector(state => state.forex.ForexLayout);
  const { subPortal, portal: P } = useSelector(state => state.Layout);
  const { clientData } = useSelector(state => state.Profile);
  const [showSubmitIndProfileModal, setShowSubmitIndProfileModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { title } = props;
  const onSwitchIbToClient = (value) => {
    if (value === CUSTOMER_SUB_PORTALS.IB) {
      localStorage.setItem("subPortal", CUSTOMER_SUB_PORTALS.IB);
      dispatch(switchSubPortal(CUSTOMER_SUB_PORTALS.IB));
    } else {
      localStorage.setItem("subPortal", CUSTOMER_SUB_PORTALS.LIVE);
      dispatch(switchSubPortal(CUSTOMER_SUB_PORTALS.LIVE));
    }
  };


  useEffect(() => {
    if (history.location?.state?.subPortal) {
      dispatch(switchSubPortal(history.location?.state?.subPortal));
    }
  }, []);

  useEffect(() => {
    let newOptions = [];
    if (clientData?.fx?.isClient) {
      newOptions.push({
        value: CUSTOMER_SUB_PORTALS.LIVE,
        label: t("Client Portal"),
      });
    }
    if (clientData?.fx?.isIb) {
      newOptions.push({
        value: CUSTOMER_SUB_PORTALS.IB,
        label: t("IB Portal"),
      });
    }
    if (clientData?.fx?.isInvestor) {
      newOptions.push({
        value: CUSTOMER_SUB_PORTALS.INVESTOR,
        label: t("Investor Portal"),
      });
    }
    if (clientData?.fx?.isSp) {
      newOptions.push({
        value: CUSTOMER_SUB_PORTALS.SP,
        label: t("Signal Provider Portal"),
      });
    }
    setOptions(newOptions);
  }, [clientData]);

  const {
    layoutMode,
  } = useSelector(state => ({
    layoutMode: state.Layout.layoutMode,
  }));


  const customStyles = {
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#495057",
      padding: 0,
      paddingRight: "5px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none"
    }),
    control: (provided) => {
      if (layoutMode === "dark") {
        return {
          ...provided,
          backgroundColor: "#242632",
          border: 0,
          boxShadow: "0 0.125rem 0.25rem #0B182F",
          color: "#adb5bd",
          height: "50%",
        };
      }
      return {
        ...provided,
      };
    },
    menu: (provided) => ({
      ...provided,
      backgroundColor: layoutMode === "dark" ? "#242632" : "white",
      color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      zIndex: 3,
    }),
    option: (provided, state) => ({
      ...provided,
      display: state.isDisabled ? "none" : "flex",
      flexDirection: "row",
      alignItems: "center",
      color: layoutMode === "dark" ? "#adb5bd" : "#495057",
    }),
    singleValue: (provided) => {
      return {
        ...provided,
        flexDirection: "row",
        alignItems: "center",
        color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      };
    },
  };

  const convertClickHandler = () => {
    dispatch(convertProfile());
    dispatch(logoutUser(props.history));
  };

  return (
    <>
      <MetaTags>
        {t(title)}
      </MetaTags>
      <Row>
        <Col xs={12}>
          <div className="mb-3 d-flex">
            {portal && clientData?.fx?.isIb && clientData?.fx?.isClient && <>
              <FormGroup check className="my-auto me-3">
                <Input
                  name="radio2"
                  type="radio"
                  id="client"
                  checked={subPortal === CUSTOMER_SUB_PORTALS.LIVE}
                  onChange={() => {
                    onSwitchIbToClient(CUSTOMER_SUB_PORTALS.LIVE);
                    history.push("/dashboard");
                  }}
                />
                <Label check for="client">
                  {t("Client Portal")}
                </Label>
              </FormGroup>
              <FormGroup check className="my-auto">
                <Input
                  name="radio2"
                  type="radio"
                  id="ib"
                  checked={subPortal === CUSTOMER_SUB_PORTALS.IB}
                  onChange={() => {
                    onSwitchIbToClient(CUSTOMER_SUB_PORTALS.IB);
                    history.push("/dashboard");
                  }}
                />
                <Label check for="ib">
                  {t("IB Portal")}
                </Label>
              </FormGroup>
            </>}
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col>
          <h1 className="mb-3">{shouldDisplayWithoutTitle ? t(title) : ""}</h1>
        </Col>
        <Col>
          <div className="d-flex justify-content-end">
            <Button className="color-bg-btn color-white mx-3 btn btn-light shadow border border-0 " onClick={() => history.push("/platforms")}
            >
              {t("Download Platform")}
            </Button>

            {subPortal === CUSTOMER_SUB_PORTALS.DEMO && (
              <Button
                className="color-bg-btn color-white mx-3 btn btn-light shadow border border-0"
                onClick={convertClickHandler}
              >
                {t("Convert To Live")}
              </Button>
            )}
            {subPortal === CUSTOMER_SUB_PORTALS.LIVE && (
              <>
                <Link to= "/web-trader">
                  <Button className="mx-3 btn btn-light shadow border border-0 color-bg-btn color-white" 
                  >
                    {t("Web Trader")}
                  </Button>
                </Link>

                <Button
                  className="mx-3 btn btn-light shadow-lg border border-0"
                  style={{
                    backgroundColor: "#39b54a",
                    color: "white",
                  }}
                  onClick={() => {
                    JClickHandler(
                      "selectDepositMethodModal",
                      clientData?.stages,
                      dispatch,
                      toggleCurrentModal,
                      subPortal,
                      P,
                    )();
                  }}
                >
                  {t("Deposit")}
                </Button>
                <SubmitIndvidualProfile
                  t={(str) => { return str }}
                  show={showSubmitIndProfileModal}
                  toggle={() => {
                    setShowSubmitIndProfileModal(!showSubmitIndProfileModal);
                    localStorage.setItem(HIDE_JOU_IND_PROFILE, true);
                  }} />
              </>
            )}
          </div>
        </Col>
      </Row>
    </>);
}

export default PageHeader;