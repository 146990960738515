import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Row,
} from "reactstrap";
import { useTranslation, withTranslation } from "react-i18next";
import {
  addDeposit,
  addForexDeposit,
  cancelFiniticPay,
  paymentGatewayDeposit,
} from "apis/deposit";
import { allowedMethods } from "./Methods/allowedMethods";
import WireTransfer from "./Methods/WireTransfer";
import Others from "./Methods/Others";
import CardWrapper from "components/Common/CardWrapper";
import CustomModal from "components/Common/CustomModal";
import StageTwo from "./StageTwo";
import { useDispatch, useSelector } from "react-redux";
import { fetchWallets, getAccountsStart } from "store/actions";
import OlxForex from "./Methods/OlxForex";
import CompanyCrypto from "./Methods/Crypto";
import Local from "./Methods/Local";
import PerfectMoney from "./Methods/PerfectMoney";
import A3tmad from "./Methods/A3tmad";
import Payer from "./Methods/Payer";
import Epayme from "./Methods/Epayme";
import DepositStatus from "./DepositStatus";

function Deposit({ isOpen, toggleOpen, type }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Selectors
  const { wallets } = useSelector((state) => state?.walletReducer);
  // States
  const [activeStep, setActiveStep] = useState(0);
  const [gateway, setGateway] = useState("");
  const [result, setResult] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  // used to check if amount is less than 15 then raise an error
  const [hoveredPaymentMethod, setHoveredPaymentMethod] = useState();
  const [paymentPayload, setPaymentPayload] = useState({});
  const { clientData } = useSelector((state) => state.Profile);
  const clientEmail = clientData?.email;

  const [isFirstStepValid, setIsFirstStepValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [baseCurrency, setBaseCurrency] = useState("USD");
  const targetCurrency = "USD";
  const [paymentStatus, setPaymentStatus] = useState({
    isSuccess: false,
    isError: false,
    loading: true,
    result: ""
  });

  // Effects
  useEffect(() => {
    if (isOpen) {
      setActiveStep(0);
      setGateway("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (type === "mt5Deposit") {
      dispatch(getAccountsStart(
        {
          type: "LIVE",
        },
      ));
    } else if (!wallets) {
      dispatch(fetchWallets());
    }
  }, [type]);

  // Handlers
  function toggleTab(tab) {
    if (activeStep !== tab) {
      setActiveStep(tab);
    }
  }

  function loadScript(scriptURL, callback) {
    const script = document.createElement("script");
    script.src = scriptURL;
    script.async = true;
    script.onload = () => {
      if (callback) {
        callback();
      }
    };
    document.body.appendChild(script);
  } 

  // useEffect(()=>{
  //   if (selectedPaymentMethod === "EPAYME" && result.result && result.isSuccess){
  //     setPaymentStatus({
  //       ...paymentStatus,
  //       loading: true
  //     });
  //     let Epay = null;
  //     const initiateEPaySDK = (url, callback) => {
  //       loadScript(url, () => {
  //         console.log("epay is ready");
  //         Epay = window.Epay;

  //         if (callback) {
  //           callback(Epay);
  //         }
  //       });
  //     };
  //     initiateEPaySDK(result.result.url, (ePayMe) => {
  //       if (ePayMe) {
  //         if (result.result.orderId != "") {
  //           setPaymentStatus({
  //             ...paymentStatus,
  //             loading: true
  //           });
  //           const options = {
  //             customerId: result.result.customerId,
  //             channelId: "WEB",
  //             merchantId: result.result.merchantId,
  //             orderID: result.result.orderID,
  //             orderDescription: result.result.orderDescription,
  //             orderAmount: result.result.orderAmount,
  //             mobilenumber: result.result.mobilenumber,
  //             email: result.result.email || "",
  //             merchantType: "ECOMMERCE",
  //             orderCurrency: "USD",
  //             successHandler: async function (response) {
  //               console.log("success", response);      
  //               let success = false;
  //               switch (response.response.transt) {
  //                 case "completed":
  //                   success = true;
  //                   setPaymentStatus({
  //                     ...paymentStatus,
  //                     isSuccess: true,
  //                     loading: false
  //                   });
  //                   break;
  //                 case "failed":
  //                   setPaymentStatus({
  //                     ...paymentStatus,
  //                     isError: true,
  //                     loading: false
  //                   });
  //               }
  //               setPaymentStatus({
  //                 ...paymentStatus,
  //                 loading: false
  //               });              },
  //             failedHandler: async function (response) {
  //               setPaymentStatus({
  //                 ...paymentStatus,
  //                 isError: true,
  //                 loading: false
  //               });
  //             }
  //           };
  //           const ePay = new ePayMe(options);
  //           ePay.open();
  //         }
  //       }
  //     });
  //   } else if (result.result){
  //     if (result.status) {
  //       setPaymentStatus({
  //         ...paymentStatus,
  //         isSuccess: true
  //       });
  //     } else {
  //       setPaymentStatus({
  //         ...paymentStatus,
  //         isError: true
  //       });
  //     }
  //   }
  //   return () => {
  //     setPaymentStatus({
  //       isError: false,
  //       isSuccess: false,
  //       loading: false
  //     });
  //   };
  // }, [result]);

  const stageOnePaymentDetails = () => {
    switch (gateway) {
      case "WIRE_TRANSFER":
        return (
          <WireTransfer t={t} setIsFirstStepValid={setIsFirstStepValid}
            setBaseCurrency={setBaseCurrency} setPaymentPayload={setPaymentPayload}  />
        );
      case "LOCAL_WIRE_TRANSFER":
        return (
          <WireTransfer t={t} setIsFirstStepValid={setIsFirstStepValid}
            setBaseCurrency={setBaseCurrency} isLocal={true} />
        );
      case "FINITIC_PAY":
      case "EPAYME":
      case "OLX_FOREX":
        return (
          <OlxForex t={t} setIsFirstStepValid={setIsFirstStepValid} />
        );
      case "PERFECT_MONEY":
        return (
          <PerfectMoney t={t} setIsFirstStepValid={setIsFirstStepValid} />
        );
      case "PAYER":
        return (
          <Payer t={t} setIsFirstStepValid={setIsFirstStepValid} />
        );
      case "حواله بنكيه":
        return (
          <A3tmad t={t} setIsFirstStepValid={setIsFirstStepValid} setPaymentPayload={setPaymentPayload} />
        );
      // case "EPAYME":
      //   return (
      //     <Epayme type={type} t={t} setIsFirstStepValid={setIsFirstStepValid}
      //       setBaseCurrency={setBaseCurrency} />
      //   );  
      case "NETELLER":
      case "MASTERCARD":
      case "SKRILL":
        return (
          <Others t={t} setIsFirstStepValid={setIsFirstStepValid} />
        );
      case "LOCAL_DEPOSITOR":
        return (
          <Local t={t} setIsFirstStepValid={setIsFirstStepValid} setPaymentPayload={setPaymentPayload} />
        );  
      case "CRYPTO":
        return (
          <CompanyCrypto
            t={t} setIsFirstStepValid={setIsFirstStepValid} setPaymentPayload={setPaymentPayload}
            setBaseCurrency={setBaseCurrency} />
        );
      default:
        return (
          <div className="text-center">
            <h5 className="mb-3">
              {t("Please Select Payment Method")}
            </h5>
          </div>
        );
    }
  };

  const submitHandler = (data) => {
    if (type === "fiatDeposit") {
      if (["OLX_FOREX", "ASIA_BANKS", "EPAYME", "FINITIC_PAY"].includes(selectedPaymentMethod)) {
        data.currency = targetCurrency;
        paymentGatewayDeposit(data, selectedPaymentMethod).then((res) => {
          console.log("testing", res);
          setResult(res);
          toggleTab(2);
          if (res.result.url && !["FINITIC_PAY", "EPAYME"].includes(selectedPaymentMethod)){
            window.location.href = res.result.url;
          }
        }).catch((err) => {
          setResult(err);
          toggleTab(2);
        });
      } else {
        addDeposit(data).then((res) => {
          setLoading(false);
          setResult(res);
          toggleTab(2);
        }).catch((err) => {
          setLoading(false);
          setResult(err);
          toggleTab(2);
        });
      }

    } else if (type === "mt5Deposit") {
      if (["ASIA_BANKS", "EPAYME", "FINITIC_PAY"].includes(selectedPaymentMethod)) {
        data.currency = targetCurrency;
        paymentGatewayDeposit(data, selectedPaymentMethod).then((res) => {
          setResult(res);
          toggleTab(2);
          if (res.result.url &&  !["FINITIC_PAY", "EPAYME"].includes(selectedPaymentMethod)) {
            window.location.href = res.result.url;
          } 
        }).catch((err) => {
          setResult(err);
          toggleTab(2);
        });
        return;
      } else {
        addForexDeposit(data).then((res) => {
          setResult(res);
          toggleTab(2);
        }).catch((err) => {
          setResult(err);
          toggleTab(2);
        });
      }
    }
  };

  const steps = [
    {
      header: t("Select Method"),
      content: (
        <>
          <div>
            <div className="mb-0">
              <h6 className="mb-3">
                {t("Select Payment Method")}
              </h6>
              <Row className="justify-content-center payment-methods">
                {
                  allowedMethods
                    .filter((method) => {
                      // if (!clientEmail?.endsWith("mailinator.com") && method.gateway === "EPAYME") {
                      //   return false;
                      // }
                      return method.allowed.includes(type);
                    })
                    .map((method) => (
                      <Col key={method.gateway} xs={4} lg={2} className="my-2">
                        <button
                          type="button"
                          onClick={() => {
                            setGateway(method.gateway);
                            setSelectedPaymentMethod(method.gateway);
                          }}
                          onMouseEnter={() => { setHoveredPaymentMethod(method.gateway) }}
                          onMouseLeave={() => { setHoveredPaymentMethod() }}
                          className={`btn btn-${selectedPaymentMethod === method.gateway ? "success" : hoveredPaymentMethod === method.gateway ? "default" : "light"} waves-effect waves-light w-sm py-4`}
                          style={{
                            maxWidth: "80px",
                            maxHeight: "80px",
                          }}
                        >
                          <img
                            src={method.image}
                            width="100%"
                            height="100%"
                            alt=""
                          />
                        </button>
                      </Col>
                    ))
                }
              </Row>
            </div>
            {gateway && stageOnePaymentDetails()}
            <div className="text-center mt-4">
              <Button
                type="submit"
                className="btn btn-success waves-effect waves-light w-lg btn-sm"
                disabled={((gateway === "") || !isFirstStepValid)}
                onClick={() => toggleTab(1)}
              >
                {t("Continue")}
              </Button>
            </div>
          </div>
        </>
      ),
    },
    {
      header: "Enter Amount",
      content: (
        <StageTwo
          t={t}
          setLoading={setLoading}
          loading={loading}
          toggleTab={toggleTab}
          type={type}
          gateway={gateway}
          handleSubmit={submitHandler}
          paymentPayload={paymentPayload}
          receipt={allowedMethods.find((method) => method.gateway === gateway)?.receipt}
          targetCurrency={targetCurrency}
          baseCurrency={baseCurrency}
        />
      ),
    },
    {
      header: "Deposit status",
      content: (
        <DepositStatus
          result={result}
          selectedPaymentMethod={selectedPaymentMethod}
          loading={result.loading}
          isSuccess={result.isSuccess}
          t={t}
          type={type}
          isError={result.isError}
        ></DepositStatus>
      ),
    },
  ];
  console.log(result);
  return (
    <>
      <CustomModal
        steps={steps}
        isOpen={isOpen}
        toggleOpen={() => {
          if (activeStep === 2 && gateway === "FINITIC_PAY") {
            cancelFiniticPay(result?.result?.url);
          }
          toggleOpen();
        }}
        activeStep={activeStep}
        toggleTab={toggleTab}
      ></CustomModal>
    </>

  );
}
export default withTranslation()(Deposit); 
