import {
  Col, Row, Container 
} from "reactstrap";
import { withTranslation } from "react-i18next";
import CardWrapper from "components/Common/CardWrapper";
import "./Platform.css";

const Platform = ({  t, image, logo, title, subTitle, isLocalImg, onDownload }) => {
  return (
    <Container fluid className="platform-container">
      <Row>
        <Col>
          <CardWrapper className="platform-card">
            <div className="header-section">
              <Row>
                <Col md={6}>
                  <div className="qr-section d-flex justify-content-between flex-row">
                    <div className="qr-container">
                      <img src="/img/platforms/apple-dl.png" alt="App Store" className="qr-code"/>
                    </div>
                    <div className="qr-container">
                      <img src="/img/platforms/google-play-dl.png" alt="Google Play" className="qr-code"/>
                    </div>
                    <div className="qr-container">
                      <img src="/img/platforms/app-gallary-dl.png" alt="App Gallery" className="qr-code"/>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="download-section">
                    <div className="d-flex flex-row justify-content-between gap-5">
                      <div className="d-flex flex-column gap-3">
                        <a href={onDownload} className="download-link main-download">{t("Download MetaTrader 5 for Windows")}</a>
                        <a href={onDownload} className="download-link">{t("WebTerminal")}</a>
                      </div>
                      <div className="d-flex flex-column gap-3">
                        <a href={onDownload} className="download-link">{t("Get macOS")}</a>
                        <a href={onDownload} className="download-link">{t("Get Linux")}</a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="content-section">
              <div className="platform-logo">
                <img src={isLocalImg ? `/img/platforms/${logo}.png` : logo} alt="MetaTrader 5" height="350px" width="350px"/>
              </div>
              <div className="platform-info d-flex flex-row">
                <div className="card-info">
                  <h5 className="platform-title fw-bold">
                    {t("Successful trading starts with convenient and functional trading. MetaTrader 5 is the best choice for the modern trader.")}
                  </h5>
                </div>
                <div className="card-description">
                  <p className="platform-description">
                    {t("With the revolutionary Mobile Trader 5 platform, you can profit from your trading endeavors and learn at your own pace. Use the MT5 app to access the powerful functionality at your disposal.")}
                  </p>
                </div>
              </div>
            </div>
          </CardWrapper>
        </Col>
      </Row>
    </Container>
  );
};

export default withTranslation()(Platform);